.slidecontainer {
  position: relative;
  width: 100%; /* Width of the outside container */
  height: 20px;
}

/* The slider itself */
.slider,
.slider-bar {
  position: absolute;
  top: 0;
  left: 0;
  background: none;
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  margin-left: -5px; /* to align left */
  width: calc(100% + 10px); /* Full-width + range to align right */
  height: 15px; /* Specified height */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  pointer-events: none;
}

/* Mouse-over effects */
.slider:hover,
.slider-bar:hover,
.slider-vertical:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb,
.slider-bar::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  border-bottom: 15px solid #0673c1;
  cursor: pointer; /* Cursor on hover */
  pointer-events: all;
}

.slider-bar::-webkit-slider-thumb {
  margin-top: -105px;
  padding-top: 105px;
  cursor: e-resize; /* Cursor on hover */
}
.slider-vertical-outer {
  position: absolute;
  left: 25%;
  margin-top: -10px;
  top: 0;
  height: 100%;
}
.slider-vertical {
  position: absolute;
  background: none;
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  left: 25%;
  writing-mode: bt-lr; /* IE */
  width: 124px;
  margin-left: -60px;
  height: calc(100% + 20px);
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
  pointer-events: none;
  transform: rotate(270deg);
}
.slider-vertical::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  width: 10px;
  height: 10px;
  background: white;
  border: 1px solid black;
  pointer-events: all;
  cursor: n-resize;
}

.hover-label {
  display: none;
  pointer-events: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: -moz-none;
  -o-user-select: none;
  user-select: none;
}
.histogram-outer:hover .hover-label {
  display: block;
}
#root .MuiToggleButton-root {
  height: auto;
  padding: 0;
  margin-right: 2px;
}
